import React, { PropsWithChildren, useMemo } from 'react';
import { useRouter } from 'next/router';

import { PageLoad } from '~/components/modules/PageLoad/PageLoad';
import ContentContainer from '~/containers/ContentContainer/ContentContainer';
import FooterContainer from '~/containers/FooterContainer/FooterContainer';
import HeaderContainer from '~/containers/HeaderContainer/HeaderContainer';
import { useAuthentication } from '~/hooks/useAuthentication';
import { BaseRoutes, TradeRoutes } from '~/routes/applicationRoutes';
import { matchPathname } from '~/utils/pathnames/matchPathname';
import { Flex } from '~/vinovest-design-system/components';
import { AppCtx } from '~/containers/AppContainer/context';

export interface AppContextInterface {
    themeType?: string | '';
    navBackgroundColor?: string | '';
}

export interface AppContainerProps {
    isLoading?: boolean;
    blank?: boolean;
    hide?: boolean;
    theme?: string;
    navBackgroundColor?: string;
}

const PrivateAppContainer: React.FC<PropsWithChildren<AppContainerProps>> = ({
    isLoading,
    children,
    blank,
    hide,
    theme,
    navBackgroundColor
}: PropsWithChildren<AppContainerProps>) => {
    const router = useRouter();
    const { isAuthenticated } = useAuthentication();

    const hideInvestmentFooterBannerPaths = [
        BaseRoutes.Dashboard,
        BaseRoutes.Trade,
        BaseRoutes.Compare,
        BaseRoutes.Dashboard,
        TradeRoutes.Collections
    ];

    const isWineDirectory = matchPathname([TradeRoutes.WineDirectory], router.asPath, false);
    const themeType = isWineDirectory ? 'trading' : 'brown400';
    const showFooterInvestingBanner =
        !isAuthenticated && !matchPathname(hideInvestmentFooterBannerPaths, router.asPath, false);

    const hideFooter = useMemo(() => router.asPath.includes('deposit'), [router.asPath]);

    const settings = {
        themeType: theme || themeType,
        navBackgroundColor
    };

    if (hide) {
        return <>{children}</>;
    }
    return (
        <AppCtx.Provider value={settings}>
            <Flex classnames={'flex-col min-h-screen'}>
                <HeaderContainer blank={blank} />
                <ContentContainer>
                    <PageLoad ready={!isLoading}>{children}</PageLoad>
                </ContentContainer>
                {!hideFooter && <FooterContainer showFooterInvestingBanner={showFooterInvestingBanner} />}
            </Flex>
        </AppCtx.Provider>
    );
};

export default PrivateAppContainer;
