import React from 'react';

import Footer from '~/components/modules/Footer';

interface Props {
    showFooterInvestingBanner: boolean;
}

const FooterContainer: React.FC<Props> = (props) => {
    return <Footer {...props} />;
};

export default FooterContainer;
