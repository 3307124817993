import React from 'react';

import { FooterContainer, GridContainer } from './Footer.styled';
import FooterDisclaimer from './FooterDisclaimer';
import FooterNavigation from './FooterNavigation';
import FooterNewsLetter from './FooterNewsLetter';
import FooterRecentArticles from './FooterRecentArticles';
import FooterStartInvestingBanner from './FooterStartInvestingBanner';
import FooterSupport from './FooterSupport';

interface Props {
    showFooterInvestingBanner: boolean;
}

const Footer: React.FC<Props> = (props) => {
    const { showFooterInvestingBanner } = props;
    return (
        <>
            {showFooterInvestingBanner && <FooterStartInvestingBanner />}
            <FooterContainer>
                <GridContainer>
                    <div className={'a'}>
                        <FooterNavigation />
                    </div>
                    <div className={'b'}>
                        <FooterRecentArticles />
                    </div>
                    <div className={'c'}>
                        <FooterNewsLetter />
                    </div>
                    <div className={'d'}>
                        <FooterSupport />
                    </div>
                </GridContainer>
                <FooterDisclaimer />
            </FooterContainer>
        </>
    );
};

export default Footer;
