import styled from 'styled-components';

export const FooterContainer = styled.footer`
    font-family: var(--font-vinovest-medium);
    width: 100%;
    margin-top: '48px';
    padding: '50px 128px';
    color: #fae8d1;
    background-color: #242e35;
    padding: 98px 128px;
    @media screen and (max-width: 1300px) {
        padding: 20px 45px 52px;
    }

    @media screen and (max-width: 1024px) {
        padding-bottom: 110px;
    }

    @media screen and (max-width: 767px) {
        padding: 38px 24px 110px;
    }

    a {
        transition: opacity 0.3s;
        &:hover {
            opacity: 0.5;
        }
    }
`;

export const GridContainer = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    background-color: #fae8d1;
    display: grid;
    grid-template-columns: minmax(275px, 1fr) minmax(275px, 1fr) 1fr 1fr;
    grid-template-rows: max-content max-content max-content max-content;
    gap: 1px 1px;
    grid-template-areas:
        'a a c c'
        'a a d d'
        'b b d d'
        'b b d d';

    .a {
        grid-area: a;
        background-color: #242e35;
    }
    .b {
        grid-area: b;
        background-color: #242e35;
    }
    .c {
        grid-area: c;
        background-color: #242e35;
    }
    .d {
        grid-area: d;
        background-color: #242e35;
    }

    @media screen and (max-width: 1300px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
            'a a'
            'b c'
            'b d';
        gap: 0 0;
        background-color: transparent;

        .a {
            padding: 0 9px;
            border-bottom: 1px solid #fae8d1;
        }

        .b {
            border-right: 1px solid #fae8d1;
        }
        .c {
            border-bottom: 1px solid #fae8d1;
        }
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            'a'
            'b'
            'c'
            'd';
        background-color: transparent;
        gap: 0px 0px;
    }
`;

export const FooterTitle = styled.h3`
    font-family: var(--font-roslindale), sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.75rem;
    margin: 0;

    @media screen and (max-width: 1300px) {
        font-size: 28px;
        line-height: 44px;
    }

    @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 34px;
    }
`;

export const NavWrapper = styled.div`
    padding-right: 40px;
    padding-bottom: 41px;
    margin-top: 40px;

    @media screen and (max-width: 1024px) {
        margin-top: 30px;
        padding-right: 0;
    }

    @media screen and (max-width: 767px) {
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 32px;
        margin-top: 0;
        border-bottom: 1px solid #fae8d1;
    }
`;

export const NavigationColumnsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 53px;
    max-width: 663.5px;
    width: 100%;

    ${FooterTitle} {
        margin-bottom: 15px;
    }

    @media screen and (max-width: 1024px) {
        padding-bottom: 42px;
        max-width: 100%;
    }

    @media screen and (max-width: 767px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 24px 42px;
        grid-template-areas:
            '. .'
            '. .';
    }
`;

export const StyledNavigation = styled.nav`
    display: flex;
    flex-direction: column;

    a {
        text-decoration: none;
        font-style: normal;
        font-weight: normal;
        font-family: var(--font-vinovest-medium);
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.875rem;
        color: inherit;
        text-align: left;

        &:not(:last-child) {
            margin-bottom: 4px;
        }

        @media screen and (max-width: 767px) {
            width: fit-content;
        }
    }
`;

export const BasicDataWrapper = styled.div`
    border: 1px solid #fae8d1;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 15px;
    margin-right: 10px;
    width: fit-content;
    font-family: var(--font-vinovest-medium);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    justify-content: space-around;
    max-width: 259px;
    width: auto;

    &:hover {
        cursor: pointer;
    }

    span {
        &.withBorder {
            border-right: 1px solid #fae8d1;
        }
    }

    @media screen and (max-width: 767px) {
        max-width: 100%;
        margin-right: 0;
    }
`;

export const BottomWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 27px;

    @media screen and (max-width: 1024px) {
        padding-right: 0;
    }
`;
