import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { AnalyticsEventMap } from '~/constants/AnalyticsEventMap';
import { useTracking } from '~/hooks/tracking/useTracking';
import { useScrollToTop } from '~/hooks/useScrollToTop';
import { ClassNamesProps } from '~/models/ClassNamesProps';

/** Individual menu links for all navigation. A menu link without a link can be used to toggle a SubMenu component. */
export interface MenuLinkProps extends HTMLAttributes<HTMLButtonElement>, ClassNamesProps {
    variant?: keyof typeof MenuLinkType;
    link?: string;
    onClick?: () => void;
    track?: { eventName: keyof AnalyticsEventMap; metaData?: Record<string, string> };
    fontClass?: string;
    activeBorderColor?: string;
}

const MenuLinkType = {
    custom: '',
    primary: 'border-transparent',
    active: 'border-black border-b'
};

const MenuLinkBase = 'cursor-pointer flex items-center text-platinum-800';

export const MenuLink: React.FC<MenuLinkProps> = ({
    children,
    className,
    classnames,
    link,
    variant,
    onClick,
    track,
    fontClass,
    activeBorderColor
}) => {
    useScrollToTop();
    const router = useRouter();
    const activeClass = router.pathname === link ? 'active' : 'primary';
    const activeBorderClass = router.pathname === link && activeBorderColor ? activeBorderColor : '';
    const { captureEvent } = useTracking();

    const linkCallback = track?.eventName
        ? () => {
              captureEvent(track.eventName, track.metaData);
              onClick && onClick();
          }
        : onClick;

    return (
        // these are bubbling
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
        <li onClick={linkCallback} className={className || classNames(MenuLinkBase, classnames)}>
            {link ? (
                <Link
                    href={link}
                    className={`flex items-center !hover:no-underline ${fontClass} ${
                        MenuLinkType[variant || activeClass]
                    } ${activeBorderColor ? activeBorderClass : ''}`}
                >
                    {children}
                </Link>
            ) : (
                <>{children}</>
            )}
        </li>
    );
};
