import { ApplicationNavigation, MarketingNavigation, MobileMarketingNavigation } from '~/components/modules/Navigation';
import { BlankNavigation } from '~/components/modules/Navigation/BlankNavigation';
import { useAuthentication } from '~/hooks/useAuthentication';

export const Header: React.FC<{ blank?: boolean }> = ({ blank }) => {
    const { isAuthenticated } = useAuthentication();
    if (isAuthenticated) {
        return <ApplicationNavigation />;
    }

    return (
        <div
            className={'print:hidden sticky'}
            id={'Header'}
            style={{ position: '-webkit-sticky', top: 0, zIndex: 20 }}
            key={'Header'}
        >
            {blank ? (
                <BlankNavigation />
            ) : (
                <>
                    <div className={'hidden lg:flex'}>
                        <MarketingNavigation />
                    </div>
                    <div className={'lg:hidden'}>
                        <MobileMarketingNavigation />
                    </div>
                </>
            )}
        </div>
    );
};
